@import "variables";
//Styles that need to be global

.offer-card-container,
.offer-card-container-mobile {
  .content-container {
    .card-text {
      ul {
        margin-bottom: 0;
        padding: 0;
      }

      li::marker {
        font-size: 18px;
        content: "• ";
        color: $green;
      }
    }
  }
}

.lac-text {
  font-family: $font-Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $dark-blue;

  a {
    color: $alt-green;
    text-decoration: underline;
  }
}

.rc-vertical-image,
.rc-horizontal-image {
  display: flex;
  align-self: center;
  margin: 16px auto 0;
  border-radius: 20px;
  object-fit: cover;

  @media (max-width: 768px) {
    max-width: 100% !important;
  }
}

.rc-vertical-image {
  max-width: 555px;
}

.rc-horizontal-image {
  max-height: 410px;
  width: 100%;
  max-width: 750px;
}

@media (max-width: 767px) {
  .instagram-media {
    min-width: 304px !important;
    max-width: 360px !important;
    align-self: center !important;
    margin: 0 !important;
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
}

// This avoids Bootstrap 5 default behaviour that underlines a by default
a {
  text-decoration: none;
}

// Buttons that need to be global to be able to style SVG's

.g-category-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px 0;
  height: 81px;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  &:not(:last-child){
    margin-right: 34px;

    @media (max-width: 767px) {
      margin-right: 0;
    }
  }

  &:last-child{
    margin-right: 16px;
  }

  .svg {
    pointer-events: none;

    * {
      stroke: $dark-grey !important;
    }
  }

  .name {
    margin-top: 4px;
    color: $dark-grey;
    text-align: center;
    font-family: $font-Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    white-space: nowrap;
  }

  &:hover {
    .svg {
      * {
        stroke: $alt-green !important;
      }
    }

    border-bottom: 2px solid $light-green;

    .name {
      color: $alt-green;
    }
  }

  &.active {
    .svg {
      * {
        stroke: $dark-green !important;
      }
    }

    border-bottom: 2px solid $dark-green;

    .name {
      color: $dark-green;
    }
  }

  &.disabled {
    border-bottom: 2px solid transparent;
    cursor: default;

    .svg {
      * {
        stroke: $disabled-text-color !important;
      }
    }

    .name {
      color: $disabled-text-color;
    }
  }
}
