@import "variables";

//region GENERAL CLASSES

.volunteering-ob-container,
.support-ob-container,
.first-vote-ob-container,
.welcome-ob-container {
  position: absolute;
  z-index: 42000;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  background-color: $white;

  .title-1 {
    color: $dark-blue;
    text-align: center;
    font-family: $font-AlegreyaSans;
    font-style: normal;
    font-weight: 800;
  }

  .text-1 {
    color: $black;
    text-align: center;
    font-family: $font-Roboto;
    font-style: normal;
    font-weight: 400;
  }

  .title-2 {
    font-family: $font-AlegreyaSans;
    font-weight: 700;
    color: $black;
  }

  .text-2 {
    font-family: $font-Roboto;
    font-weight: 400;
    color: $black;
  }

  .stepper {
    font-family: $font-Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: $disabled-text-color;
  }

  @media screen and (max-width: 768px) {
    top: 0;
    left: 0;
    transform: unset;
    width: 100vw;
    height: 100dvh;
    border-radius: 0;
  }
}

//endregion GENERAL CLASSES

//region VOLUNTEERING, SUPPORT AND FIRST VOTE ONBOARDING CONTAINERS
.volunteering-ob-container, .support-ob-container, .first-vote-ob-container {
  width: 560px;

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 24px 24px;
    height: 100%;

    .title-1 {
      font-size: 32px;
      line-height: 30px;
      margin-bottom: 24px;
    }

    > img {
      width: 476px;
      margin-bottom: 24px;
    }

    .title-2 {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
      text-align: center;
    }

    .text-1 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: auto;

      .dots {
        margin-top: 20px;
        height: 8px;
        display: flex;
        justify-content: center;

        span {
          display: inline-block;
          margin: 0 4px;
          width: 8px;
          height: 8px;
          background-color: $disabled-background;
          border-radius: 50%;
          transition: background-color 0.6s ease;

          &.active-dot {
            background-color: $disabled-text-color;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    .step {
      padding: 32px 16px 40px;

      > img {
        width: 100%;
        object-fit: cover;
      }

      .title-1 {
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 24px;
      }

      .subtitle {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 8px;
      }

      .text-1 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
      }

      .button-container {
        flex-direction: column-reverse;

        .dots {
          margin-top: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
}

.first-vote-ob-container {
  .step {
    .title-1 {
      span {
        font-size: 51px;
      }

      small {
        font-size: 26px;
      }
    }
  }
}

//endregion VOLUNTEERING, SUPPORT AND FIRST VOTE ONBOARDING CONTAINERS

