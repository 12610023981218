@import 'variables';
// This avoids linter to complain about library class names not being kebab case
/* stylelint-disable selector-class-pattern */

// General Charts styles
.ns-simple-bar-chart {
  & .cds--cc--chart-wrapper {
    & .layout-svg-wrapper {
      rect.chart-grid-backdrop.stroked {
        stroke: none;
      }

      .cds--cc--axes {
        & g.axis {
          &.left {
            & path.domain {
              display: none;
            }
          }

          &.bottom {
            & path.domain {
              stroke-width: 4px !important;
              stroke: #bcbcbc !important;
              transform: translate(0, 1px);
            }
          }
        }
      }
    }
  }
}

// Campaign Earned Points Chart
.campaign-earned-points {
  .cds--cc--chart-wrapper text {
    font-weight: 500;
    font-family: $font-Roboto !important;
  }
}
