@use '@angular/material' as mat;

@import "variables";
@import "common";
@import "buttons";
@import "inputs";
@import "svg";
@import "tooltips";
@import "charts";
@import "global";
@import "onboardings";

// Fonts Imports (Alegreya, Alegreya Sans, Roboto & Open Sans)
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600&display=swap");

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$noos-primary: mat.define-palette(mat.$indigo-palette, 500);
$noos-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$noos-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$noos-theme: (
  color: mat.define-light-theme(
    $primary: $noos-primary,
    $accent: $noos-accent,
    $warn: $noos-warn
  ),
  typography: mat.define-typography-config(),
  density: 0  // Set to default or adjust as needed
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($noos-theme);

// Carbon Design charts
@import "~@carbon/charts/styles.css";

// NooS Main scrollbar style
@include ns-main-scrollbar($dark-blue, $white);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100vh;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 0;
}

#axeptio_overlay {
  z-index: 9999 !important;
}

#fc_frame {
  z-index: 9998 !important;
}

//#region ANGULAR MATERIAL STYLE OVERRIDES

.backdropBackground {
  background: rgb(0 0 0 / 75%);
}

// Workaround to avoid body scrolling and header disapearing (because the body moves 100px to the top
// due the Dialog scrolling strategy) when a dialog is opened.
.cdk-global-scrollblock {
  position: static !important;
  overflow-y: hidden !important;
  width: initial !important;
}

// Mat Dialog custom styles

.cdk-overlay-container {
  z-index: 10000 !important;
}

.mat-mdc-dialog-container {
  .mat-mdc-dialog-surface {
    border-radius: 15px !important;
  }
}

.default-old-panel,
.project-dialog,
.validate-impact-dialog,
.subscription-dialog,
.edit-payment-dialog,
.edit-subscription-dialog,
.crisis-event-dialog,
.matching-gift-dialog,
.no-kpi-matching-gift-dialog,
.vote-dialog {
  .mat-mdc-dialog-surface {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 900px !important;
    height: auto !important;
    padding: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: 15px !important;

    @media (max-width: 767px) {
      position: fixed;
      top: 0;
      left: 0;
      min-width: 100vw;
      height: 100vh;
    }
  }
}

.vote-dialog {
  .mat-mdc-dialog-surface {
    max-width: 737px;
  }
}

.subscription-dialog,
.edit-payment-dialog,
.edit-subscription-dialog,
.crisis-event-dialog,
.matching-gift-dialog,
.no-kpi-matching-gift-dialog {
  .mat-mdc-dialog-surface {
    max-width: 953px;
  }
}

.cause-dialog,
.continent-dialog,
.country-dialog,
.credential-changed-dialog {
  .mat-mdc-dialog-surface {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 907px !important;
    height: auto !important;
    padding: 0;
    background-color: transparent !important;
    box-shadow: none !important;

    @media (max-width: 900px) {
      position: fixed;
      top: 0;
      left: 0;
      min-width: 100vw;
      height: 100vh;
      border-radius: 0 !important;
    }
  }
}

.change-password-dialog {
  .mat-mdc-dialog-surface {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 571px;
    height: auto;
    padding: 0;
    background-color: transparent;
    box-shadow: none;

    @media (max-width: 564px) {
      position: fixed;
      top: 0;
      left: 0;
      min-width: 100vw;
      height: 100vh;
      border-radius: 0 !important;
    }
  }
}

.change-email-dialog,
.stop-subscription-dialog {
  .mat-mdc-dialog-surface {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 701px;
    height: auto;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    border-radius: 15px;

    @media (max-width: 694px) {
      position: fixed;
      top: 0;
      left: 0;
      min-width: 100vw;
      height: 100vh;
      border-radius: 0 !important;
    }
  }
}

.default-fullscreen-panel {
  max-height: 100dvh !important;
  height: 100dvh;

  .ns-close-button {
    position: absolute;
    z-index: 1;
    top: -12px;
    right: -12px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    border-radius: 50%;
    background: no-repeat center/14px url("/assets/icons/cross-dark-blue.svg"), white;

    &:hover {
      background-color: $light-green;
    }
  }

  .mat-mdc-dialog-surface {
    padding: 20px;
    border-radius: 0 !important;
  }
}

.no-padding-fullscreen-panel {
  height: 100%;

  .ns-close-button {
    position: absolute;
    z-index: 1;
    top: -12px;
    right: -12px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    border-radius: 50%;
    background: no-repeat center/14px url("/assets/icons/cross-dark-blue.svg"), white;

    &:hover {
      background-color: $light-green;
    }

    @media (max-width: 767px) {
      top: 8px;
      right: 8px;
    }
  }

  .mat-mdc-dialog-surface {
    padding: 0;
    border-radius: 0 !important;
  }
}

.quiz-fullscreen-panel {
  height: 100%;

  .mat-mdc-dialog-surface {
    padding: 0;
    border-radius: 0 !important;
  }
}

.fullscreen-visible-header-panel {
  height: calc(100vh - 97px);
  margin-top: 97px;

  .mat-mdc-dialog-surface {
    border-radius: 0!important;
    padding: 24px;
  }

  @media (max-width: 930px) {
    height: calc(100vh - 70px);
    margin-top: 70px;
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    margin-top: 0 !important;
    height: 100vh !important;
  }
}

.default-panel {
  ::-webkit-scrollbar-track {
    margin: 12px 0;
  }

  height: auto;
  max-height: 85vh;

  .ns-close-button {
    position: absolute;
    z-index: 1;
    top: -38px;
    right: -38px;
    width: 37px;
    height: 37px;
    cursor: pointer;
    border-radius: 50%;
    background: no-repeat center/15px url("/assets/icons/cross-dark-blue.svg"), white;

    &:hover {
      background-color: $light-green;
    }
  }

  .mat-mdc-dialog-surface {
    padding: 48px;
    border-radius: 15px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.padding-16-panel {
  .mat-mdc-dialog-surface {
    padding: 16px;
    border-radius: 8px;
  }

  .ns-close-button {
    position: absolute;
    z-index: 1;
    top: -16px;
    right: -16px;
    width: 37px;
    height: 37px;
    cursor: pointer;
    border-radius: 50%;
    background: no-repeat center/15px url("/assets/icons/cross-dark-blue.svg"), white;

    &:hover {
      background-color: $light-green;
    }
  }

  @media (max-width: 700px) {
    margin-top: 0 !important;
  }
}

.challenge-detail-panel {
  .mat-mdc-dialog-surface {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    box-shadow: none;
  }
}

.lac-onboarding-panel {
  height: auto !important;
  max-height: 85vh !important;

  ::-webkit-scrollbar-track {
    margin: 12px 0;
  }

  .mat-mdc-dialog-surface {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: auto !important;
    padding: 16px !important;
    padding-bottom: 0 !important;
    border-radius: 15px;
  }
}

// Mat Bottom Sheets Modal custom styles

.full-screen-bottom-sheet {
  overflow: hidden !important;
  min-width: 100vw !important;
  height: calc(100vh - 70px);
  max-height: unset !important;
  padding: 0 !important;
}

.no-padding-panel {
  .mat-mdc-dialog-surface {
    padding: 0;
    border-radius: 10px;
  }

  .ns-close-button {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 37px;
    height: 37px;
    cursor: pointer;
    border-radius: 50%;
    background: no-repeat center/15px url("/assets/icons/cross-dark-blue.svg"), white;

    &:hover {
      background-color: $light-green;
    }
  }

  @media (max-width: 700px) {
    margin-top: 0 !important;
  }
}

.challenge-result-panel {
  .mat-mdc-dialog-surface {
    padding: 0;
    border-radius: 15px;
  }

  @media (max-width: 700px) {
    margin-top: 0 !important;
  }
}

.no-padding-radius-15-panel {
  max-height: 90vh;

  .mat-mdc-dialog-surface {
    padding: 0;
    border-radius: 15px;
    height: auto;

    /* Safari specific style to avoid visible overflow borders */
    -webkit-mask-image: radial-gradient(white, black);
  }
}

.default-modal-panel {
  max-height: unset !important;

  .mat-mdc-dialog-surface {
    padding: 0;
    border-radius: 15px;
  }
}

// Mat Expansion Panel custom styles

mat-expansion-panel-header {
  .mat-expansion-indicator {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: transparent;

    &:after {
      position: absolute;
      top: 13.5px;
      left: 11px;
      display: block;
      width: 20px;
      height: 13px;
      padding: 0;
      content: url("/assets/icons/dropdown-arrow-green.svg");
      transform: none;
      border: none;
    }
  }

  &.mat-expanded {
    .mat-expansion-indicator {
      background-color: $light-green;
    }
  }
}

// Mat Select general custom styles

mat-select {
  font-family: $font-Roboto;
  font-size: 16px;
  line-height: 24px;
  color: $dark-grey;
  .mat-mdc-select-trigger {
    display: flex;
    align-items: center;
    height: 33px;
   .mat-mdc-select-value {
      max-width: unset;
      color: inherit;
      .mat-mdc-select-placeholder {
        color: $light-grey;
      }
    }
    .mat-select-arrow-wrapper {
      position: absolute;
      top: 0;
      right: -8px;
      display: block;
      width: 35px;
      height: 100%;

      &:before {
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        border-left: 1px solid $light-grey;
        background: no-repeat center/9px 14px url("/assets/icons/dropdown-arrow-dark-yellow.svg"), $light-yellow;
      }
      .mat-select-arrow {
        display: none;
      }
    }
  }
}

// Mat Select with buttons custom styles

.cdk-overlay-container .cdk-overlay-pane .mat-select-with-buttons {
  position: absolute;
  top: 1px;
  left: 39px;
  width: 260px;
  min-width: 260px !important;
  max-height: unset;
  padding-top: 8px;
  padding-bottom: 0;
  border-right: 5px;
  border-radius: 5px !important;

  .mat-mdc-option {
    height: 30px;
    min-height: 30px;
    padding: 0 12px 0 18px;

    mat-pseudo-checkbox {
      font-family: $font-AlegreyaSans;
      width: 18px;
      height: 18px;
      border: 1px solid #5a6170;
      border-radius: 4px;

      &.mat-pseudo-checkbox-checked {
        border: 1px solid $dark-blue !important;
        background-color: $dark-blue !important;

        &:after {
          top: -1px;
          left: 2px;
          width: 9px;
          height: 5px;
          border-radius: 1px;
        }
      }
    }
    .mdc-list-item__primary-text {
      font-family: $font-AlegreyaSans !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      height: 24px !important;
      color: $dark-grey !important;
    }

    &.mat-mdc-active,
    &:hover {
      background: none;
    }

    &.mdc-list-item--disabled {
      mat-pseudo-checkbox {
        border: 1px solid #d3d3d3;
        background-color: #d3d3d3;
      }
      .mdc-list-item__primary-text {
        color: #d3d3d3;
      }
    }
  }

  hr {
    margin-top: 4px;
    margin-bottom: 0;
  }

  .panel-buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 10px 10px 40px;

    .ns-button-pill-dark-grey-transparent-small {
      margin-right: 2px;
    }

    ns-button:first-child,
    .ns-button-pill-dark-grey-transparent-small {
      min-width: 64px !important;
      padding: 1px 0;
    }
  }
}

//#endregion ANGULAR MATERIAL STYLE OVERRIDES

.custom-chart-tooltip {
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  height: 70px;
  padding: 15px;
  border-radius: 5px;
  background: $white;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);

  img {
    width: 40px;
    height: 40px;
    margin-right: 15px;
  }

  div {
    font-family: $font-AlegreyaSans;
    font-size: 14px;
    line-height: 16px;
    color: $dark-blue;
  }
}

// TODO: Remove this when we improve styling
.research-impact-carousel ul.indicators {
  bottom: 0 !important;

  > li {
    padding: 4px !important;
    cursor: default !important;
  }
}
