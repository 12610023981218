@import "variables";

/* ----------------- VARIABLES ----------------- */
$input-border-radius-default: 10px;
$input-font-family-default: $font-AlegreyaSans;

/* ----------------- MIXINS ----------------- */

@mixin ns-input-type-text(
  $font-size,
  $line-height,
  $font-color,
  $border-color,
  $background-color,
  $placeholder-font-size:$font-size,
  $placeholder-font-color:$font-color,
  $background-color-hover:$background-color,
  $font-color-hover:$font-color,
  $border-color-hover:$border-color,
  $background-color-focus:$background-color-hover,
  $font-color-focus:$font-color-hover,
  $border-color-focus:$border-color-hover,
) {
  font-family: $input-font-family-default;
  font-size: $font-size;
  background: $background-color;
  border-radius: $input-border-radius-default;
  border: 1px solid $border-color;
  color: $font-color;
  line-height: $line-height;
  text-align: left;
  position: relative;
  z-index: 1;

  &::placeholder {
    position: absolute;
    line-height: $line-height;
    font-size: $placeholder-font-size;
    color: $placeholder-font-color;
  }

  &:hover {
    background: $background-color-hover;
    border-color: $border-color-hover;
    color: $font-color-hover;
  }

  &:focus {
    outline: none;
    background: $background-color-focus;
    border-color: $border-color-focus;
    color: $font-color-focus;
  }

  &:disabled {
    @include input-disabled;
  }

  &.invalid {
    @include input-invalid;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

@mixin input-invalid(
  $border-color: $warn-color,
  $font-color: $warn-color
) {
  border-color: $border-color;
  color: $font-color;
}

@mixin input-disabled(
  $disabled-border-color:$gris,
  $disabled-text-color: $gris,
  $disabled-background: transparent
) {
  border: 1px solid $disabled-border-color;
  color: $disabled-text-color;
  background: $disabled-background;
}

/* ----------------- CLASSES ----------------- */

.donation-amount-input {
  position: relative;

  input {
    @include ns-input-type-text(
      40px,
      43px,
      // default
      $dark-blue,
      $dark-blue,
      transparent,
      // placeholder
      20px,
      $light-grey,
      // hover & focus
      transparent,
      $dark-blue,
      $dark-blue);

    height: 54px;
    width: 210px;
    min-width: 210px;
    font-weight: 500;
    padding: 11px 6px 11px 45px;

    &::placeholder {
      color: $light-grey;
      font-weight: 500;
      line-height: 32px;
    }
  }

  .ns-placeholder {
    position: absolute;
    padding: 11px 6px 11px 45px;
    color: $light-grey;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    z-index: 0;
  }

  .left-icon {
    font-family: $input-font-family-default;
    color: $dark-blue;
    position: absolute;
    line-height: 43px;
    font-size: 36px;
    font-weight: 500;
    top: 5px;
    left: 15px;

    &.invalid {
      color: $warn-color;
    }
  }
}
