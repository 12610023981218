@import "variables";

.ns-tooltip-container-1 {
  background: $very-light-green;
  z-index: 100;
  position: absolute;
  padding: 20px;
  border-radius: 5px;
  width: auto;
  max-width: 500px;
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-50%, -50%);
  animation: tooltip-slide 0.18s ease-out 0.5s;
  animation-fill-mode: forwards;
  pointer-events: none;

  .tooltip-title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 800;
    color: $dark-blue;
    font-family: $font-AlegreyaSans;
    margin-bottom: 4px;
  }

  .tooltip-text {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $dark-blue;
    font-family: $font-Roboto;
  }
}

.ns-tooltip-container-2 {
  background: #efefef;
  z-index: 100;
  position: absolute;
  padding: 7px 12px;
  border-radius: 5px;
  width: auto;
  max-width: 500px;
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-50%, -50%);
  animation: tooltip-slide 0.18s ease-out 0.5s;
  animation-fill-mode: forwards;
  display: flex;
  pointer-events: none;

  .tooltip-title {
    font-size: 14px;
    line-height: 16px;
    font-weight: 800;
    color: $dark-blue;
    font-family: $font-AlegreyaSans;
    margin-bottom: 4px;
  }

  .tooltip-text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: $very-dark-green;
    font-family: $font-AlegreyaSans;

    a {
      text-decoration: underline;
      cursor: pointer;
      color: $dark-green;
      pointer-events: all;
    }
  }
}

.ns-tooltip-max-content {
  width: max-content;
  max-width: 280px;
}

.ns-ellipsis-tooltip-container {
  background: $very-light-green;
  z-index: 999999;
  position: absolute;
  padding: 4px 8px;
  border-radius: 5px;
  width: auto;
  min-width: 100px;
  max-width: 420px;
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-50%, -50%);
  animation: tooltip-slide 0.18s ease-out 0.5s;
  animation-fill-mode: forwards;
  pointer-events: none;

  .tooltip-text {
    font-size: 11px;
    line-height: 15px;
    font-weight: 500;
    color: $dark-blue;
    font-family: $font-Roboto;
  }
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
